<template>
  <RouterLink :to="`/goods/${goods.id}`" class="goods-item">
    <img :src="minioUrl + goods.squareThumbUrl" :alt="goods.name" />
    <p class="name ellipsis">{{ goods.name }}</p>
    <p class="desc ellipsis">{{ goods.extensions }}</p>
    <!-- <p class="price">&yen;{{ goods.price }}</p> -->
  </RouterLink>
</template>

<script>
const { minioURL } = window.settings;
export default {
  name: "GoodsItem",
  props: {
    goods: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const minioUrl = minioURL + "/";
    return { minioUrl };
  },
};
</script>

<style scoped lang="less">
.goods-item {
  display: block;
  width: 275px;
  min-height: 100%;
  padding: 10px 10px;
  text-align: center;
  .hoverShadow();
  img {
    width: 240px;
    height: 160px;
  }
  p {
    padding-top: 10px;
  }
  .name {
    font-size: 16px;
  }
  .desc {
    color: #999;
    height: 29px;
  }
  .price {
    color: @priceColor;
    font-size: 20px;
  }
}
</style>
